import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';

type BatchCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export const BatchCell = ({ className, prism_batch_id }: BatchCellProps) => {
    return (
        <PlainText className={className} value={prism_batch_id ?? ''} />
    );
};
