import { usePayrollGroupedCellDictionary } from 'modules/payrollProcessorHub/components/PayrollSheetTable/hooks/usePayrollGroupedCellDictionary';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFeature } from '@optimizely/react-sdk';
import { Permission } from 'store/components/auth/authModels';
import { selectIsUserHasPermission } from 'store/components/auth/selectors';

import { usePayrollSheetCheckbox } from './usePayrollSheetCheckbox';
import { makeStickyCellCustomizer } from '../helpers/makeStickyCellCustomizer';
import { IPayrollSheetGroupedRow } from '../model';
import { showIf } from '../helpers/showIf';
import { PayrollSheetTabs } from 'modules/payrollProcessorHub/store/model';
import { useDefaultTableStyles } from 'shared/components/table/GridTable/defaultStyles';
import { ICellInfo } from 'shared/components/table/GridTable/GridTableModel';
import { FeatureSwitches } from 'utils/featureSwitches';
import { selectCalculationsHasFiles, selectCalculationsHasMultipleApprovers } from 'modules/payrollProcessorHub/store/selectors';
import { useStickyCellsStyles } from 'modules/payrollProcessorHub/components/PayrollSheetTable/styles/useStickyCellsStyles';
import { useActivePayrollSheetStatus } from 'modules/payrollProcessorHub/store/helpers';

export const approvedLevelTabs: Array<PayrollSheetTabs> = [
    PayrollSheetTabs.ALL,
    PayrollSheetTabs.SUBMITTED,
    PayrollSheetTabs.APPROVED,
];

export const usePayrollGroupedCalculationTableCells = (): ICellInfo<IPayrollSheetGroupedRow>[] => {
    const activeTab = useActivePayrollSheetStatus();
    const tableClasses = useDefaultTableStyles();
    const stickyCellsClasses = useStickyCellsStyles();
    const calculationsHasFiles = useSelector(selectCalculationsHasFiles);
    const calculationsHasMultipleApprovers = useSelector(selectCalculationsHasMultipleApprovers);

    const checkbox = usePayrollSheetCheckbox(activeTab, tableClasses);
    const cellDictionary = usePayrollGroupedCellDictionary();
    const showStatus = activeTab === PayrollSheetTabs.ALL;
    const showBatchData = activeTab === PayrollSheetTabs.CLOSED;
    const showMiles = !showStatus;
    const showApprovedLevel = approvedLevelTabs.includes(activeTab);
    const [enableApprovedByCell] = useFeature(FeatureSwitches.enablePPHFilterByManager);
    const [enablePtoAndPslCell] = useFeature(FeatureSwitches.enableSeparatePtoAndPsl);
    const [enableUpdatedByPph] = useFeature(FeatureSwitches.enableUpdatedByPph);
    const withStickyStyles = useMemo(() => makeStickyCellCustomizer(), []);
    const userHasEditPermission = useSelector(selectIsUserHasPermission(Permission.payrollProcessing));

    return useMemo(() => {
        const approversCellTitle = calculationsHasMultipleApprovers ? 'approvers' : 'approver';

        if (activeTab === PayrollSheetTabs.OVERDUE) {
            return [
                cellDictionary.employee,
                cellDictionary.payPeriod,
                cellDictionary.payPeriodInfo,

                ...showIf( // Client
                    !process.env.REACT_APP_CLIENT_ID,
                    cellDictionary.client,
                ),

                { // Approvers
                    ...cellDictionary.approvers,
                    title: approversCellTitle,
                },

                cellDictionary.totalPay,
                cellDictionary.overdueDetails,
            ];
        }

        if (activeTab === PayrollSheetTabs.MISSING) {
            return [
                cellDictionary.type,
                cellDictionary.employee,
                cellDictionary.payPeriod,
                cellDictionary.payPeriodInfo,

                ...showIf( // Client
                    !process.env.REACT_APP_CLIENT_ID,
                    cellDictionary.client,
                ),

                { // Approvers
                    ...cellDictionary.approvers,
                    title: approversCellTitle,
                },

                ...showIf( // Details
                    userHasEditPermission,
                    cellDictionary.details,
                ),
            ];
        }

        return [
            ...checkbox,
            cellDictionary.type,
            cellDictionary.payType,

            ...showIf( // Client
                !process.env.REACT_APP_CLIENT_ID,
                cellDictionary.client,
            ),

            cellDictionary.payPeriod,
            cellDictionary.payPeriodInfo,

            ...showIf(
                showBatchData,
                cellDictionary.batchId,
            ),
            ...showIf(
                showBatchData,
                cellDictionary.payrollProcessedAt,
            ),

            cellDictionary.employee,

            ...showIf( // Files
                calculationsHasFiles,
                cellDictionary.files,
            ),

            cellDictionary.regularHours,
            cellDictionary.overtimeHours,
            cellDictionary.doubleTimeHours,
            ...showIf( // Miles
                enablePtoAndPslCell,
                cellDictionary.pto,
                cellDictionary.psl,
            ),
            cellDictionary.expenses,

            ...showIf( // Miles
                showMiles,
                cellDictionary.miles,
            ),

            ...showIf( // Status
                showStatus,
                cellDictionary.status,
            ),

            ...showIf( // Approved Level
                showApprovedLevel,
                cellDictionary.approvedLevel,
            ),

            ...showIf( // Approved By
                enableApprovedByCell,
                cellDictionary.approvedByCell,
            ),

            ...showIf( // Edited By
                enableUpdatedByPph && (activeTab === PayrollSheetTabs.APPROVED
                    || activeTab === PayrollSheetTabs.ALL
                    || activeTab === PayrollSheetTabs.CLOSED),
                cellDictionary.editedByCell,
            ),

            withStickyStyles( // Total Pay
                cellDictionary.totalPay,
                stickyCellsClasses.totalPayStickyHeader,
                stickyCellsClasses.totalPayStickyCell,
            ),

            withStickyStyles( // Details
                cellDictionary.details,
                stickyCellsClasses.detailsStickyHeader,
                stickyCellsClasses.detailsStickyCell,
            ),
        ];
    }, [
        activeTab,
        calculationsHasFiles,
        calculationsHasMultipleApprovers,
        cellDictionary,
        checkbox,
        enableApprovedByCell,
        showApprovedLevel,
        showMiles,
        showStatus,
        stickyCellsClasses,
        userHasEditPermission,
        withStickyStyles,
        enablePtoAndPslCell,
        enableUpdatedByPph,
        showBatchData,
    ]);
};
