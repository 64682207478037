import { PayrollCells } from 'modules/payrollProcessorHub/components/PayrollSheetTable/model';
import { useMemo } from 'react';

export function usePayrollCellsSizes() {
    return useMemo(() => ({
        [PayrollCells.Details]: '70px',
        [PayrollCells.OverdueDetails]: '204px',
        [PayrollCells.Type]: '35px',
        [PayrollCells.Employee]: '2fr',
        [PayrollCells.PayPeriod]: '130px',
        [PayrollCells.PayPeriodInfo]: '40px',
        [PayrollCells.Client]: '3fr',
        [PayrollCells.ApprovedLevel]: '80px',
        [PayrollCells.JobNumber]: '80px',
        [PayrollCells.DealNumber]: '80px',
        [PayrollCells.Files]: '130px',
        [PayrollCells.BatchId]: '100px',
        [PayrollCells.PayrollProcessedAt]: '150px',
        [PayrollCells.RegularHours]: '130px',
        [PayrollCells.OvertimeHours]: '130px',
        [PayrollCells.DoubleTimeHours]: '130px',
        [PayrollCells.PTO]: '130px',
        [PayrollCells.PSL]: '130px',
        [PayrollCells.Expenses]: '100px',
        [PayrollCells.Miles]: '90px',
        [PayrollCells.TotalPay]: '110px',
        [PayrollCells.Status]: '150px',
        [PayrollCells.ApprovedByCell]: '2fr',
        [PayrollCells.Approvers]: '2fr',
        [PayrollCells.PayType]: '30px',
    }), []);
}
