/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useMemo, useRef } from 'react';
import { Formik } from 'formik';
import { Box } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { PayrollFilterForm } from 'modules/payrollProcessorHub/components/PayrollFilter/PayrollFilterForm';
import {
    getCFieldByPrism,
    getPayrollPayPeriods,
    setPayrollProcessorFilter,
} from 'modules/payrollProcessorHub/store/actions';
import { IPayrollProcessorFilters } from 'modules/payrollProcessorHub/store/model';
import {
    selectPayrollFilter,
    selectPPHFilterClientHasAvionteConfiguration,
    selectPPHFilterClientHasJobNumberConfiguration,
} from 'modules/payrollProcessorHub/store/selectors';
import {
    selectAllNotDeletedCustomFieldValues, selectCustomFieldHierarchyNodesByIds,
    selectCustomFields,
} from 'store/entities/customFields/selectors';

interface IPayrollFilterProps {
    isDashboard?: boolean;
}

export const PayrollFilter = ({
    isDashboard,
}: IPayrollFilterProps) => {
    const dispatch = useDispatch();
    const initialValues = useSelector(selectPayrollFilter);
    const clientHasJobNumberConfiguration = useSelector(selectPPHFilterClientHasJobNumberConfiguration);
    const clientIsAvionteBound = useSelector(selectPPHFilterClientHasAvionteConfiguration);
    const currentClientId = useRef<string>('');

    const onChange = useCallback((values: Partial<IPayrollProcessorFilters>) => {
        dispatch(setPayrollProcessorFilter(values));
    }, [dispatch]);

    const onChangeClient = useCallback((clientId: string | null) => {
        const payload = clientId ? { client_id: clientId } : undefined;
        dispatch(getPayrollPayPeriods.init(payload));
        dispatch(getCFieldByPrism({ clientId: clientId ?? '', prismFieldId: 'department' }));
        currentClientId.current = clientId ?? '';
    }, [dispatch]);

    const allCustomFieldValues = useSelector(selectAllNotDeletedCustomFieldValues);
    const allCustomFields = useSelector(selectCustomFields);
    const allHierarchy = useSelector(selectCustomFieldHierarchyNodesByIds);
    const departments = useMemo(() => {
        const clientId = (currentClientId.current ?? '');
        const departmentFieldIds = allCustomFields.filter(x => x.prism_field?.key === 'department').map(x => x.id);
        const currentClientHierarchyNodesIds = allHierarchy
            ? Object.values(allHierarchy).filter(x => x.client_id === clientId).map(x => x.custom_field_id)
            : null;
        return allCustomFieldValues.filter(x => x.client_id === clientId
            && departmentFieldIds.includes(x.custom_field_id)
            && (currentClientHierarchyNodesIds && currentClientHierarchyNodesIds?.includes(x.custom_field_id)));
    }, [allCustomFieldValues, allCustomFields, allHierarchy, currentClientId]);

    return (
        <Box>
            <Formik
                initialValues={initialValues}
                onSubmit={() => {}}
                validateOnBlur={false}
            >
                {props => (
                    <PayrollFilterForm
                        {...props}
                        onChange={onChange}
                        onChangeClient={onChangeClient}
                        actualFilterValues={initialValues}
                        isDashboard={isDashboard}
                        clientHasJobNumberConfiguration={clientHasJobNumberConfiguration}
                        clientDepartmentValues={departments}
                        clientIsAvionteBound={clientIsAvionteBound}
                    />
                )}
            </Formik>
        </Box>
    );
};
