import React from 'react';
import PlainText from 'shared/components/table/Cells/PlainText';
import { ICellProps } from 'shared/components/table/GridTable/GridTableModel';
import { IGroupedSheetCalculationCommon } from 'modules/payrollProcessorHub/store/model';

type PayrollProcessedAtCellProps = ICellProps<IGroupedSheetCalculationCommon>;

export const PayrollProcessedAtCell = ({
    className,
    prism_batch_id,
    payroll_processed_at,
}: PayrollProcessedAtCellProps) => {
    const isAvionteBatch = prism_batch_id ? prism_batch_id.length > 7 : false;
    const value = payroll_processed_at && isAvionteBatch ? payroll_processed_at.split('T', 1)[0] : '';
    return (
        <PlainText className={className} value={value} />
    );
};
